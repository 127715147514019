































































import Modal from "@/components/Modal.vue";
import { Device as DeviceService } from "@/services/SOLO";
import { Select, Option } from "element-ui";
import { ValidationObserver, configure, extend, validate } from "vee-validate";
import { Facility, Device as DeviceModel } from "@/models";
import {
  Component,
  ProvideReactive,
  Vue,
  Prop,
  Watch,
  Emit,
} from "vue-property-decorator";
import { translations, notificationAlerts } from "@/mixins";

interface DeviceModelObject {
  id: string;
  "location-id": string;
  label: string;
  uuid: string;
  "ip-address": string;
  "terminal-id": string;
}

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  mixins: [translations, notificationAlerts],
})
export default class FacilitiesFormModal extends Vue {
  open: boolean = false;
  parent: any;
  saving: boolean = false;
  $notify: any;
  $refs!: {
    formValidator: any;
  };
  model: DeviceModelObject = {
    id: "",
    "location-id": "",
    label: "",
    uuid: "",
    "ip-address": "",
    "terminal-id": "",
  };

  translate!: Function;
  systemErrorNotification!: Function;
  successNotification!: Function;

  @Prop() locations!: Array<Object>;

  closeMe() {
    this.open = false;
    this.$emit("close");
  }

  async submit(e: any) {
    const isValid = await this.$refs.formValidator.validate();
    if (!isValid || this.saving) {
      return;
    }

    this.saving = true;
    try {
      if (this.model.id) {
        let response = await DeviceService.update(this.model.id, this.model);
      } else {
        let response = await DeviceService.create(this.model);
      }

      this.successNotification();
      this.saving = false;
      this.closeMe();
      this.$emit("successfulFormSubmit");
    } catch (error) {
      console.log(error);
      this.saving = false;
      this.systemErrorNotification();
    }
  }
}
